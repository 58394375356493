<template>
  <div class='content'>
    <div class='card' style='display: flex;'>
      <div class="left">
        <el-button type='primary' @click='dialogVisible = true' icon='el-icon-plus'>新增仓库</el-button>
        <el-button type='primary' plain @click='handleExport' icon='el-icon-upload2' :loading="loading3">导出</el-button>
      </div>  
      <div class='line'></div>
      <div class="middle">
        <Cond @onCond="handleCond" function_code="warehouse" />
      </div>
      <div class="right">
        <TableConf function_code="warehouse" @onRowList="handleRowList"/>
      </div>
    </div>
    <div class='table' v-if="rowList.length">
      <el-table :data='data.list' stripe v-loading="loading" height="700">
        <template v-for='(item, index) in rowList'>
          <el-table-column
            v-if="item.filed_status"
            align='center'
            :key='index'
            :prop="item.field_alias"
            :label='item.field_text'
            :width="item.t_width"
            :filters="item.filed_status"
            :filter-method="filterHandler"
          >
            <template slot-scope="scope">
              <template v-if="item.field_alias !== 'warehouse_status'">
                {{scope.row[item.field_alias] ? scope.row[item.field_alias]: '--'}}
              </template>
              <template v-else>
                <el-switch
                  v-model='scope.row.warehouse_status'
                  :active-value="10"
                  :inactive-value="20"
                  :disabled="scope.row.hierarchy_id == 1"
                  @change="handleSwitch(scope.row)"
                >
                </el-switch>
              </template>
            </template>
          </el-table-column>
          <el-table-column
            v-else
            align='center'
            :key='index'
            :prop="item.field_alias"
            :label='item.field_text'
            :width="item.t_width"
          >
            <template slot-scope="scope">
              <template v-if="item.field_alias !== 'warehouse_status'">
                {{scope.row[item.field_alias] ? scope.row[item.field_alias]: '--'}}
              </template>
              <template v-else>
                <el-switch
                  v-model='scope.row.warehouse_status'
                  :active-value="10"
                  :inactive-value="20"
                  :disabled="scope.row.hierarchy_id == 1"
                  @change="handleSwitch(scope.row)"
                >
                </el-switch>
              </template>
            </template>
          </el-table-column>
        </template>
        <el-table-column align='center' fixed='right' field_alias='operation' label='操作'>
          <template slot-scope='scope'>
            <el-button type='text' @click="handleEdit(scope.row)" :disabled="scope.row.hierarchy_id == 1">编辑</el-button>
            <el-button type='text' @click="handleDele(scope.row)" style="color: #F56C6C;">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class='pagina'>
      <el-pagination
        background
        @size-change="handleSizeChange"
        layout='total, sizes, prev, pager, next, jumper'
        :total='data.total'
        :page-size='limit'
        :page-sizes="$store.state.pageSizes"
        @current-change='handleCurrentChange'
      ></el-pagination>
    </div>
    <el-dialog
      :title="form.warehouse_id? '编辑仓库': '新增仓库'"
      :visible.sync='dialogVisible'
      width='30%'
      :destroy-on-close="true"
      :before-close='handleClose'>
      <el-form :model='form' label-width='100px' :rules="rules" ref="form">
        <el-form-item label='仓库名称' field_alias='warehouse_name' required>
          <el-input v-model='form.warehouse_name'></el-input>
        </el-form-item>
        <!-- <el-form-item label='所属门店' field_alias='hierarchy_id' required>
          <el-select v-model='form.hierarchy_id' placeholder='请选择所属门店' :disabled="form.canEditShop? false: true">
            <template v-for="item in merchantList">
              <el-option
                :label='item.hierarchy_name'
                :value='item.hierarchy_id'
                :key="item.hierarchy_id">
              </el-option>
            </template>
          </el-select>
        </el-form-item> -->
        <el-form-item label='启用状态' field_alias='warehouse_status'>
          <el-switch
            v-model='form.warehouse_status'
            :active-value="10"
            :inactive-value="20">
          </el-switch>
        </el-form-item>
        <el-form-item label='排序' field_alias='sort' required>
          <el-input v-model='form.sort' type="number"></el-input>
        </el-form-item>
      </el-form>
      <span slot='footer' class='dialog-footer'>
        <el-button @click='handleClose'>取 消</el-button>
        <el-button type='primary' @click='handleTrue' :loading="loading2">确 定</el-button>
      </span>
    </el-dialog>
    <!-- <dragTreeTable :data="treeData" :onDrag="onTreeDataChange" hightRowChange="true" ></dragTreeTable> -->
  </div>
</template>

<script>
import { listReq, getMerchantListReq, saveReq, deleWarehouseReq } from '@/api/goods/goodsStore/list';
import moment from 'moment';
import Cond from '@/components/cond/Index.vue';
import TableConf from '@/components/tableConf/Index.vue';
import dragTreeTable from "drag-tree-table";

export default {
  data() {
    return {
      treeData: {
        columns: [
          { title: '测试', align: 'center', field: 'test', flex: 1 },
          { title: '测试1', align: 'center', field: 'test1', flex: 1 },
          { title: '测试2', align: 'center', field: 'test2', flex: 1 },
          { title: '测试3', align: 'center', field: 'test3', flex: 1 },
        ],
        lists: [
          { id: 0, test: '你妹的', test1: '你妹的', test2: '你妹的', test3: '你妹的', order: 0, lists: [] },
          { id: 1, test: '啊啊啊啊啊啊啊', test1: '啊啊啊啊啊啊啊', test2: '啊啊啊啊啊啊啊', test3: '啊啊啊啊啊啊啊', order: 1, lists: [] },
          { id: 2, test: '哦哦哦', test1: '哦哦哦', test2: '哦哦哦', test3: '哦哦哦', order: 2, lists: [] },
        ],
      },
      loading3: false,
      rowList: [
        // {
        //   field_text: '仓库名称',
        //   field_alias: 'warehouse_name',
        // },
        // {
        //   field_text: '所属门店',
        //   field_alias: 'hierarchy_name',
        // },
        // {
        //   field_text: '状态',
        //   field_alias: 'warehouse_status',
        // },
        // {
        //   field_text: '添加人',
        //   field_alias: 'create_user_name',
        // },
        // {
        //   field_text: '添加时间',
        //   field_alias: 'create_time',
        // },
      ],
      loading2: false,
      rules: {
        warehouse_name: [
          { required: true, message: '请填写仓库名称' },
        ],
        // hierarchy_id: [
        //   { required: true, message: '请选择所属门店' },
        // ],
        // warehouse_status: [
        //   { required: true, message: '请选择启用状态' },
        // ],
      },
      merchantList: [],
      loading: false,
      form: {
        warehouse_status: 20,
        canEditShop: 1,
        sort: 1,
      },
      dialogVisible: false,
      total: 0,
      tableVisiable: false,
      cond: {},
      page: 1,
      limit: this.$store.state.pageSizes[0],
      // coordinate: { column: -1, row: -1 },
      data: [],
    };
  },
  components: {
    Cond,
    TableConf,
    dragTreeTable,
  },
  created() {
    this.getList();
    this.getMerchantList();
  },
  methods: {
    handleDele(row) {
      this.$confirm('确实要删除该仓库吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        deleWarehouseReq(row.warehouse_id)
          .then((res) => {
            if (res.code === 1) {
              this.$message({
                type: 'success',
                message: '删除成功!',
              });
              this.getList();
            }
          });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除',
        });
      });
    },
    onTreeDataChange(list) {
      console.log('拖拽');
      console.log(list);
      this.treeData.lists = list;
    },
    handleSizeChange(limit) {
      this.page = 1;
      this.limit = limit;
      this.getList();
    },
    filterHandler(value, row, column) {
      const { property } = column;
      return row[property] === value;
    },
    // 处理表格数据
    handleRowList(rowList) {
      this.rowList = rowList;
    },
    handleCond(cond) {
      this.page = 1;
      this.cond = { ...cond };
      this.getList();
    },
    handleSwitch(row) {
      this.form = { ...row };
      this.save();
    },
    handleEdit(row) {
      this.form = { ...row };
      this.form.hierarchy_id = "" + this.form.hierarchy_id;
      this.dialogVisible = true;
    },
    save() {
      this.loading2 = true;
      saveReq(this.form)
        .then((res) => {
          if (res.code === 1) {
            this.$message.success('操作成功');
            this.loading2 = false;
            this.dialogVisible = false;
            this.handleClose();
            this.getList();
          }
        })
        .catch(() => {
          this.$message.error('操作失败');
          this.loading2 = false;
        });
    },
    handleTrue() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.save();
        } else {
          return false;
        }
      });
    },
    getMerchantList() {
      getMerchantListReq()
        .then((res) => {
          if (res.code === 1) {
            this.merchantList = res.data;
          }
        });
    },
    getList() {
      this.loading = true;
      listReq({ ...this.cond, page: this.page, limit: this.limit })
        .then((res) => {
          if (res.code === 1) {
            this.loading = false;
            res.data.list.forEach((item) => {
              if (item.create_time) {
                item.create_time = moment(item.create_time * 1000).format('YYYY-MM-DD HH:mm:ss');
              }
            });
            this.data = res.data;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    handleClose () {
      this.dialogVisible = false;
      this.form = {
        canEditShop: 1,
        warehouse_status: 20,
        sort: 1,
      };
    },
    // getCellIndex ({ row, column, rowIndex, columnIndex }) {
    //   row.index = rowIndex;
    //   column.index = columnIndex;
    // },
    // hanldeCellClick(row, column) {
    //   const coordinate = { ...this.coordinate };
    //   coordinate.row = row.index;
    //   coordinate.column = column.index;
    //   this.coordinate = coordinate;
    // },
    fetchData() {
      this.getList();
    },
    /*
     *  导出
     */
    handleExport() {
      this.loading3 = true;
      listReq({ ...this.cond, page: this.page, page_num: this.limit, function_code: 'warehouse', export: 2 })
        .then((res) => {
          this.loading3 = false;
          let blob = new Blob([res], { type: "application/vnd.ms-excel" });
          const a = document.createElement("a");
          a.setAttribute("download", `仓库列表.csv`);
          a.href = URL.createObjectURL(blob);
          a.click();
        })
        .catch(() => {
          this.loading3 = true;
        });
    },
    /*
     *  新增入库
     */
    handleAdd() {
      this.addVisiable = true;
    },
    /*
     *  检索
     */
    handleChange(val) {
      if (val === 1) {
        this.detailVisiable = true;
      }
      if (val === 2) {
        this.examineVisiable = true;
      }
    },
    /*
     *  分页操作
     */
    handleCurrentChange(page) {
      this.page = page;
      this.getList();
    },
    /*
     *  关闭列表配置弹窗
     */
    // cancelTable() {
    //   const coordinate = { ...this.coordinate };
    //   coordinate.row = -1;
    //   coordinate.column = -1;
    //   this.coordinate = coordinate;
    //   this.tableVisiable = false;
    // },
    /*
     *  关闭选择入库类型弹窗
     */
    cancelAdd() {
      this.addVisiable = false;
    },
  },
};
</script>

<style lang="less" scoped>
.middle {
  flex: 1;
}
</style>
